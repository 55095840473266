<template>
	<div class="w-full flex flex-col justify-start items-center px-10 lg:px-24 2xl:px-80 py-10 lg:py-16 gap-12">
		<div v-if="false" class="w-full flex justify-center items-center flex-wrap gap-12">
			<a
				v-for="client, i in clients"
				:key="client.name"
				:href="client.url"
				target="_blank"
				class="w-fit h-fit flex justify-center items-center hover:scale-105 active:scale-95 transition"
			>
				<img
					:ref="`client${i}`"
					:src="client.img"
					:alt="client.name"
					class="w-28 lg:w-36 h-auto"
				/>
			</a>
		</div>

		<div ref="info" class="w-full border-t-2 border-secondary pt-12">
			<div class="w-full h-full flex flex-col justify-center items-start gap-2">
				<div
					class="
						flex flex-col justify-center items-start gap-2
						text-clamp-lg text-primary uppercase relative
					"
				>
					<h2 class="relative">
						<div class="absolute -top-5 translate-x-full -right-2 rotate-12">
							<AlertIcon />
						</div>

						Brzo
					</h2>
					<h2>
						Jednostavno
					</h2>
					<h2>
						Povoljno
					</h2>
				</div>
				<span class="text-clamp-md">
					Izrada, održavanje i ažuriranje web stranica, web shopova i mobilnih aplikacija.
				</span>
				<span class="text-clamp-md">
					Za detaljniju ponudu i informacije slobodno nas <a class="link" href="/kontakt">kontaktirajte</a>.
				</span>
			</div>
		</div>
	</div>
</template>

<script>
import { gsap } from 'gsap';
import AlertIcon from '@/assets/icons/AlertIcon.vue';

export default {
	name: 'ClientsPage',
	components: {
		AlertIcon,
	},
	setup() {
		return {
			clients: [
				{
					name: 'House Ivana',
					img: 'images/clients/house-ivana.png',
					url: 'https://house-ivana.com/',
				},
				{
					name: 'Fradol Apartments',
					img: 'images/clients/fradol.png',
					url: 'https://fradol-apartments.com/',
				},
				{
					name: 'Anelis',
					img: 'images/clients/anelis.png',
					url: 'https://anelis.hr/',
				},
			],
		};
	},
	mounted() {
		gsap.from(
			this.$refs.info,
			{
				opacity: 0,
				x: -this.$refs.info.clientWidth,
				duration: 1,
			}
		);

		for (let i = 0; i < this.clients.length; i++) {
			gsap.from(
				this.$refs[`client${i}`],
				{
					opacity: 0,
					duration: 1,
					delay: i * 1 / this.clients.length,
				}
			);
		}
	}
}
</script>
