<template>
	<div ref="modalWrapper" class="fixed top-0 left-0 w-screen h-svh z-[999999] overflow-hidden hidden">
		<div class="w-full h-full relative">
			<div class="w-full h-full absolute top-0 left-0 bg-[#000000AA] z-0" @click="handleCloseModal" />

			<div class="w-full h-full flex justify-center items-center px-5 md:px-10 py-5 z-20">
				<div class="w-full h-full bg-white rounded-lg z-20 relative">
					<span class="absolute top-2 right-4 text-black">
						<font-awesome-icon
							:icon="['fas', 'x']"
							class="transition hover:scale-110 active:scale-95 cursor-pointer"
							@click="handleCloseModal"
						/>
					</span>
					
					<div ref="modalContent" class="w-full h-full p-4 overflow-auto">
						<component :is="modal" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { gsap } from 'gsap';
import { useModalStore } from '@/stores/modal';

export default {
	name: 'ModalComponent',
	setup() {
		return {
			modalStore: useModalStore(),
		};
	},
	mounted() {
		if (this.modal) {
			this.handleModalShow();
		}
	},
	methods: {
		handleModalShow() {
			gsap.fromTo(
				this.$refs.modalWrapper,
				{
					opacity: 0,
				},
				{
					duration: 0.5,
					opacity: 1,
					ease: 'power4.out',
					onStart: () => {
						this.handleBodyOverflow(true);
					},
				},
			);
		},
		handleCloseModal() {
			gsap.to(this.$refs.modalWrapper, {
				duration: 0.5,
				opacity: 0,
				ease: 'power4.out',
				onComplete: () => {
					this.modalStore.closeModal();
					this.handleBodyOverflow(false);
				},
			});
		},
		handleBodyOverflow(showModal) {
			if (showModal) {
				window.scrollTo(0, this.modalStore.scrollTop);
				this.$refs.modalWrapper.classList.remove('hidden');
				document.body.classList.add('overflow-hidden');
			} else {
				this.$refs.modalContent.scrollTop = 0;
				this.$refs.modalWrapper.classList.add('hidden');
				document.body.classList.remove('overflow-hidden');

				this.$nextTick(() => {
					window.scrollTo(0, this.modalStore.scrollTop);
				});
			}
		},
	},
	computed: {
		modal() {
			return this.modalStore.modal;
		},
	},
	watch: {
		modal() {
			if (this.modal) {
				this.handleModalShow();
			}
		},
	},
};
</script>
