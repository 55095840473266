<template>
	<div class="w-full">
		<label v-if="label" class="block text-md lg:text-lg">
			{{ label }}
		</label>
		<select
			:value="modelValue"
			@input="$emit('update:model', $event.target.value)"
			class="block w-full lg:max-w-lg bg-white border border-primary rounded-md py-1 px-2 h-9 lg:h-11 focus:outline-primary"
		>
			<option
				value=""
				v-if="defaultOption"
			>
				{{ defaultOption }}
			</option>
			<option
				v-for="(option, index) in options"
				:key="index"
				:value="option"
				class="capitalize"
			>
				{{ option }}
			</option>
		</select>
	</div>
</template>

<script>
export default {
	name: 'SelectInput',
	props: {
		label: {
			type: String,
			default: null,
		},
		modelValue: {
			type: String,
			default: '',
		},
		options: {
			type: Array,
			default: () => [],
		},
		defaultOption: {
			type: String,
			default: null,
		},
	},
	emits: [
		'update:model'
	],
};
</script>
