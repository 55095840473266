import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import '@/styles/index.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faX, faBars } from '@fortawesome/free-solid-svg-icons';

library.add([faX, faBars]);

createApp(App)
	.component('font-awesome-icon', FontAwesomeIcon)
	.use(createPinia())
	.use(router)
	.mount('#app')
