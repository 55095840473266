<template>
	<svg :width="width" :height="height" viewBox="0 0 648 969" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_654_19)">
			<mask id="mask0_654_19" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="648" height="969">
				<path d="M647.781 0H0V968.972H647.781V0Z" fill="white"/>
			</mask>
			<g mask="url(#mask0_654_19)">
				<path d="M113.618 762.284C-74.921 781.8 25.55 1028.89 163.925 939.588C242.689 888.761 191.796 714.518 81.031 809.231" stroke="black" stroke-width="30" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M82.685 678.987C82.685 678.987 46.934 534.976 32.868 461.209C14.225 363.438 -2.88399 197.909 23.698 99.7882C58.341 -28.0901 244.444 -17.2998 255.686 118.923C266.48 249.719 134.613 632.46 134.613 632.46L107.201 714.422" stroke="black" stroke-width="30" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M495.085 762.284C306.546 781.8 407.018 1028.89 545.392 939.588C624.156 888.761 573.263 714.518 462.498 809.231" stroke="black" stroke-width="30" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M464.152 678.987C464.152 678.987 428.401 534.976 414.335 461.209C395.692 363.438 378.583 197.909 405.165 99.7882C439.809 -28.0901 625.911 -17.2998 637.153 118.923C647.947 249.719 516.08 632.46 516.08 632.46L488.668 714.422" stroke="black" stroke-width="30" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			</g>
		</g>
		<defs>
			<clipPath id="clip0_654_19">
				<rect width="647.781" height="968.972" fill="white"/>
			</clipPath>
		</defs>
	</svg>
</template>

<script>
export default {
	name: 'AlertIcon',
	props: {
		width: {
			type: Number,
			default: 22.78,
		},
		height: {
			type: Number,
			default: 34.06,
		},
	},
};
</script>
