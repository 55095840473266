export default [
	{
		name: 'default',
		primaryColor: '#FFF',
		secondaryColor: '#FFF',
		primaryFontColor: '#000',
		secondaryFontColor: '#B6B6B6',
	},
	{
		name: 'v-01',
		primaryColor: '#FF8A00',
		secondaryColor: '#AED8FF',
	},
	{
		name: 'v-02',
		primaryColor: '#CCF381',
		secondaryColor: '#03A890',
		primaryFontColor: '#03A890',
		secondaryFontColor: '#CCF381',
	},
	{
		name: 'v-03',
		primaryColor: '#F6D8F7',
		secondaryColor: '#16BAB1',
		primaryFontColor: '#16BAB1',
		secondaryFontColor: '#F6D8F7',
	},
	{
		name: 'v-04',
		primaryColor: '#FFA694',
		secondaryColor: '#CCF381',
	},
	{
		name: 'v-05',
		primaryColor: '#16BAB1',
		secondaryColor: '#F6D8F7',
	},
	{
		name: 'v-06',
		primaryColor: '#6D59E7',
		secondaryColor: '#FFA694',
	},
	{
		name: 'v-07',
		primaryColor: '#E2D1F9',
		secondaryColor: '#6D59E7',
		primaryFontColor: '#6D59E7',
		secondaryFontColor: '#E2D1F9',
	},
	{
		name: 'v-08',
		primaryColor: '#6D59E7',
		secondaryColor: '#16BAB1',
	},
	{
		name: 'v-09',
		primaryColor: '#8AAAE5',
		secondaryColor: '#FF8A00',
		primaryFontColor: '#FF8A00',
		secondaryFontColor: '#8AAAE5',
	},
	// obe boje su presvijetle
	// {
	// 	name: 'v-10',
	// 	primaryColor: '#CCF381',
	// 	secondaryColor: '#E2D1F9',
	// },
	{
		name: 'v-11',
		primaryColor: '#6D59E7',
		secondaryColor: '#CCF381',
	},
	{
		name: 'v-12',
		primaryColor: '#03A890',
		secondaryColor: '#FFA694',
	},
]
