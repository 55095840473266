<template>
	<div class="w-full grid grid-rows-2 lg:grid-rows-1 lg:grid-cols-2 relative px-10 lg:px-24 2xl:px-80 py-16">
		<div class="w-full h-full relative">
			<div
				ref="form"
				class="w-full h-full flex flex-col justify-center items-start gap-4"
			>
				<h1 class="font-medium text-xl">Pošalji nam zahtjev:</h1>

				<TextInput
					ref="nameInput"
					placeholder="Ime i prezime *"
					v-model="name"
					@update:model="name = $event"
				/>
				
				<TextInput
					placeholder="Email *"
					v-model="email"
					@update:model="email = $event"
				/>

				<SelectInput
					v-if="false"
					v-model="globalStore.orderType"
					@update:model="globalStore.orderType = $event"
					:options="['Upit', 'Po modelu', 'Po narudžbi']"
				/>

				<TextareaInput
					placeholder="Poruka"
					v-model="message"
					@update:model="message = $event"
				/>

				<span v-if="error" class="text-red-500">
					Došlo je do greške prilikom slanja poruke. Molimo pokušajte ponovno.
				</span>

				<Btn
					bg-color="bg-secondary"
					:disabled="isBtnDisabled"
					@click="sendMessage"
				>
					<span v-if="!loading" class="px-4 py-2">
						Pošalji
					</span>
					<span v-else class="px-4 py-2">
						Slanje...
					</span>
				</Btn>
			</div>

			<div
				ref="successMessage"
				class="absolute hidden w-full h-fit border bg-white border-secondary rounded-md p-4 top-1/2 left-0 -translate-y-1/2"
			>
				<span class="text-lg">
					Hvala na ukazanom povjerenju. Naš tim će ti odgovoriti u najkraćem mogućem roku.
				</span>
			</div>
		</div>
		<div class="w-full h-full">
			<div ref="contactInfo" class="w-full lg:w-fit h-full mx-auto flex flex-col justify-center items-start lg:items-start gap-8">
				<div>
					<h1 class="text-2xl 2xl:text-3xl text-primary uppercase">
						Email:
					</h1>
					<a :href="`mailto:${infoConfig.email}`">
						<span class="text-2xl link hover-underline-lg">
							{{ infoConfig.email }}
						</span>
					</a>
				</div>
				<div>
					<h1 class="text-2xl 2xl:text-3xl text-primary uppercase">
						Telefon:
					</h1>
					<a :href="`tel:${infoConfig.phone}`">
						<span class="text-2xl link hover-underline-lg">
							{{ infoConfig.phone }}
						</span>
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { gsap }	from 'gsap';
import { infoConfig } from '@/assets/config/infoConfig';
import HttpManager from '@/managers/HttpManager';
import TextInput from '@/components/form/TextInput.vue';
import TextareaInput from '@/components/form/TextareaInput.vue';
import SelectInput from '@/components/form/SelectInput.vue';
import Btn from '@/components/Btn.vue';
import { useGlobalStore } from '@/stores/global';

export default {
	name: 'ContactPage',
	components: {
		TextInput,
		TextareaInput,
		SelectInput,
		Btn,
	},
	data() {
		return {
			name: '',
			email: '',
			message: '',

			loading: false,
			error: false,
		};
	},
	setup() {
		return {
			infoConfig,
			globalStore: useGlobalStore(),
		};
	},
	mounted() {
		// focus if not mobile or tablet
		if (window.innerWidth >= 768) {
			this.$refs.nameInput.$refs.input.focus();
		}

		gsap.from(this.$refs.form, {
			opacity: 0,
			x: -this.$refs.contactInfo.offsetWidth,
			duration: 1,
		});

		gsap.from(this.$refs.contactInfo, {
			opacity: 0,
			x: this.$refs.contactInfo.offsetWidth,
			duration: 1,
		});
	},
	methods: {
		successAnimation() {
			gsap.fromTo(
				this.$refs.form,
				{
					opacity: 1,
				},
				{
					opacity: 0,
					duration: 0.5,
				},
			);

			gsap.fromTo(
				this.$refs.successMessage,
				{
					opacity: 0,
				},
				{
					opacity: 1,
					duration: 0.5,
					onStart: () => {
						this.$refs.successMessage.classList.remove('hidden');
					},
				},
			);
		},
		prepareMsg() {
			return `Novi zahtjev sa studio31.hr od ${this.name}:` + '\n'
				+ `Email: ${this.email}` + '\n'
				+ `Message: \n\n${this.message}`;
		},
		async sendMessage() {
			if (this.isSubmitDisabled) {
				return;
			}

			this.loading = true;
			this.error = false;

			const emailData = {
				authKey: infoConfig.emailAuthToken,
				subject: "Novi zahtjev za izradu web stranice",
				message: this.prepareMsg(),
			};

			try {
				const result = await HttpManager.post('/send-email', emailData);

				if (result.data?.data?.success || result.data?.success) {
					this.successAnimation();

					// logEvent(analytics, 'request_sent', {
					// 	name: this.name,
					// 	email: this.email,
					// 	guests: this.guests,
					// 	checkIn: this.checkIn,
					// 	checkOut: this.checkOut,
					// 	message: this.message,
					// });
				} else {
					this.error = true;
				}
			} catch (error) {
				this.error = true;
			} finally {
				this.loading = false;
			}
		},
	},
	computed: {
		isBtnDisabled() {
			return this.loading || !this.name || !this.email;
		},
	},
}
</script>
