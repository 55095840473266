<template>
	<button
		class="py-1 px-4 focus:outline-primary"
		:class="[bgColor, border, {
			' shadow-md hover:shadow-lg transition duration-300 active:shadow-sm cursor-pointer': !disabled,
			'cursor-not-allowed opacity-50': disabled,
		}]"
		:disabled="disabled"
	>
		<slot></slot>
	</button>
</template>

<script>
export default {
	name: 'BtnComponent',
	props: {
		bgColor: {
			type: String,
			default: 'bg-white',
		},
		border: {
			type: String,
			default: 'border-2 border-secondary rounded-xl',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
}
</script>
