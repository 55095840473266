<template>
	<div class="grid gap-4">
		<div>
			<h1 class="font-bold text-xl">Izrada po gotovom modelu</h1>
			<h3 class="font-medium text-lg">Najbrže i najpovoljnije rješenje</h3>
			<p>
				Ova opcija uključuje izradu web stranice po jednom od naših gotovih modela. U cijenu je uključeno:
			</p>
			<ul class="list-disc list-inside">
				<li>Registracija domene</li>
				<li>Hosting</li>
				<li>SSL certifikat</li>
				<li>SEO optimizacija</li>
				<li>Responsive dizajn</li>
				<li>24/7 podrška</li>
			</ul>
		</div>

		<div>
			<h1 class="font-bold text-xl">Izrada po narudžbi</h1>
			<h3 class="font-medium text-lg">Personalizirano i unikatno rješenje</h3>
			<p>
				Ova opcija uključuje izradu web stranice prema vašim idejama i zahtjevima. U cijenu je uključeno:
			</p>
			<ul class="list-disc list-inside">
				<li>Registracija domene</li>
				<li>Hosting</li>
				<li>SSL certifikat</li>
				<li>SEO optimizacija</li>
				<li>Responsive dizajn</li>
				<li>24/7 podrška</li>
			</ul>
		</div>

		<div>
			<h1 class="font-bold text-xl">Redizajn web stranice</h1>
			<h3 class="font-medium text-lg">Osuvremenite svoju web stranicu</h3>
			<p>
				Ova opcija uključuje redizajn vaše postojeće web stranice. U cijenu je uključeno:
			</p>
			<ul class="list-disc list-inside">
				<li>Registracija domene</li>
				<li>Hosting</li>
				<li>SSL certifikat</li>
				<li>SEO optimizacija</li>
				<li>Responsive dizajn</li>
				<li>24/7 podrška</li>
			</ul>
		</div>

		<div>
			<h1>Održavanje web stranice</h1>
			<h3>Briga o vašoj web stranici</h3>

		</div>
	</div>
	
</template>

<script>
export default {
	name: 'PricingDetailsModal',
};
</script>
