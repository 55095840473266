import { defineStore } from 'pinia';
import { onMounted, ref } from 'vue';
import colorVariations from '@/assets/config/colorVariations';

export const useGlobalStore = defineStore('global', () => {
	const showMenu = ref(false);

	const colors = ref(colorVariations[0]);
	
	const orderType = ref('Upit');

	onMounted(() => {
		changeColors();
	});

	const changeColors = () => {
		const currentIndex = colorVariations.findIndex(c => c.name === colors.value.name);

		let nextIndex;
		do {
			nextIndex = getRandomColorIndex();
		} while (nextIndex === currentIndex);

		colors.value = colorVariations[nextIndex];
		applyColors();
	};

	const getRandomColorIndex = () => {
		return Math.min(colorVariations.length - 1, Math.round(Math.random() * colorVariations.length));
	};

	const applyColors = () => {
		window.document.documentElement.style.setProperty(
			'--primary-color',
			colors.value.primaryFontColor || colors.value.primaryColor
		);
		window.document.documentElement.style.setProperty(
			'--secondary-color',
			colors.value.secondaryFontColor || colors.value.secondaryColor
		);
	};

	return {
		showMenu,
		colors,
		changeColors,
		orderType,
	};
});
