<template>
	<svg :width="width" :height="height" viewBox="0 0 1842 1173" fill="none" xmlns="http://www.w3.org/2000/svg">
		<mask id="mask0_657_6" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="962" y="0" width="880" height="791">
			<path d="M1841.81 0H962V790.22H1841.81V0Z" fill="white"/>
		</mask>
		<g mask="url(#mask0_657_6)">
			<path d="M1414.47 427.422C1413.61 425.719 1414.66 458.739 1415.73 466.763C1419.34 493.841 1421.74 521.015 1425.89 547.983C1433.94 600.354 1432.07 653.28 1438.58 705.346C1441.68 730.132 1447.46 755.082 1447.46 780.22" stroke="black" stroke-width="20" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M1013.44 423.258C1012.59 421.555 1013.64 454.574 1014.71 462.598C1018.32 489.676 1020.72 516.851 1024.86 543.818C1032.92 596.19 1031.05 649.116 1037.55 701.181C1040.65 725.967 1046.44 750.918 1046.44 776.056" stroke="black" stroke-width="20" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M1533.76 426.153C1535.5 422.66 1521.14 445.82 1518.53 450.265C1510.1 464.585 1501.34 478.477 1491.88 492.144C1468.94 525.277 1449.44 560.237 1427.16 593.669C1423.21 599.587 1406.85 615.806 1406.85 622.857C1406.85 626.483 1448.42 642.815 1453.81 644.431C1500.87 658.549 1545.8 671.472 1592.13 688.848C1592.54 689.001 1593.06 689.164 1593.67 689.335" stroke="black" stroke-width="20" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M1132.73 421.989C1134.48 418.495 1120.12 441.655 1117.51 446.101C1109.08 460.421 1100.32 474.313 1090.86 487.98C1067.92 521.112 1048.42 556.073 1026.13 589.504C1022.19 595.423 1005.83 611.641 1005.83 618.693C1005.83 622.319 1047.4 638.651 1052.78 640.267C1099.84 654.384 1144.77 667.307 1191.11 684.684C1191.52 684.837 1192.04 684.999 1192.65 685.171" stroke="black" stroke-width="20" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M1211.42 466.406C1211.42 494.935 1214.62 524.413 1217.76 552.702C1223.08 600.534 1230.43 695.892 1230.43 695.892C1230.43 695.892 1231.31 733.763 1235.53 737.984C1237.84 740.295 1307.86 735.446 1307.86 735.446L1338.32 732.761" stroke="black" stroke-width="20" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M1793.91 517.168C1796.82 553.401 1796.82 713.896 1805.83 774.787" stroke="black" stroke-width="20" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M1326.9 540.011C1326.9 540.011 1328.24 593.826 1331.98 619.962C1335.21 642.627 1338.32 668.338 1338.32 691.029" stroke="black" stroke-width="20" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M1636.55 768.441C1636.55 770.154 1635.44 746.408 1634.01 739.253C1630.41 721.261 1616.25 542.133 1616.25 507.015C1616.25 490.164 1616.38 418.885 1617.51 419.451C1624.64 423.013 1638.45 473.362 1641.63 482.903C1656.33 527.027 1708.42 715.02 1713.2 738.926C1713.73 741.603 1725.31 774.859 1725.38 774.787C1730.14 770.028 1726.21 600.724 1725.38 557.778C1724.37 504.889 1725.2 466.406 1722.85 408.029" stroke="black" stroke-width="20" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M1797.93 453.651C1792.31 447.524 1783 457.982 1788.3 463.284C1797.41 472.392 1803.05 459.22 1797.93 453.651Z" stroke="black" stroke-width="20" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M1328.09 466.475C1322.46 460.348 1313.16 470.805 1318.46 476.107C1327.57 485.215 1333.21 472.044 1328.09 466.475Z" stroke="black" stroke-width="20" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
		</g>
		<path d="M86.3848 1077.42C83.3444 1019.9 8.28818 790.914 10.0298 790.017C12.7826 788.598 150.88 877.345 251.409 883.281C252.558 883.348 176.866 932.072 141.804 938.478C137.828 939.205 553.327 1501.53 962 843.31" stroke="black" stroke-width="20" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
</template>

<script>
export default {
	name: 'ClickArrowIcon',
	props: {
		width: {
			type: Number,
			default: 122.8,
		},
		height: {
			type: Number,
			default: 78.2,
		},
	},
};
</script>
