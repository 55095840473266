<template>
	<table class="table-fixed border-collapse border-secondary border-2">
		<thead>
			<tr>
				<th class="border-secondary border-2 p-2" :colspan="numberColumns">
					<div class="w-full h-full grid place-content-start">
						<span class="uppercase">
							{{ heading }}
						</span>
					</div>
				</th>
			</tr>
		</thead>
		<tbody>
			<template v-if="bodyData.length > 0">
				<tr v-for="row, i in bodyData" :key="i">
					<td
						v-for="k, j in Object.keys(row)"
						:key="j"
						class="border-secondary border-2 py-2"
						:class="{
							'text-left pl-2 pr-8 py-2': j === 0,
							'text-primary text-right p-2': j === numberColumns - 1,
						}"
					>
						{{ row[k] }}
					</td>
				</tr>
			</template>
			<tr v-else>
				<td class="border-secondary border-2 px-4 py-2">
					<slot></slot>
				</td>
			</tr>
		</tbody>
	</table>
</template>

<script>
export default {
	name: 'SimpleTable',
	props: {
		heading: {
			type: String,
			required: true,
		},
		bodyData: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		numberColumns() {
			return this.bodyData.length > 0 ? Object.keys(this.bodyData[0]).length : 1;
		},
	},
};
</script>
