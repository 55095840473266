import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useModalStore = defineStore('modal', () => {
	const modal = ref(null);
	const scrollTop = ref(0);

	const openModal = (component, _scrollTop) => {
		scrollTop.value = _scrollTop;
		modal.value = component;
	};

	const closeModal = () => {
		modal.value = null;
	};

	return {
		modal,
		scrollTop,
		openModal,
		closeModal,
	};
});
