<template>
	<div ref="header" class="w-full h-fit grid place-content-center p-8 relative z-10">
		<div class="absolute top-4 left-4">
			<DiceIcon
				ref="dice" 
				:size="42" 
				class="cursor-pointer block"
				@mouseover="bendDice"
				@mouseleave="unBendDice"
				@click="handleDiceClick"
			/>
		</div>

		<div v-if="showClickArrowIcon" class="absolute top-[calc(0rem+0px)] left-[calc(1rem+50px)]">
			<ClickArrowIcon />
		</div>

		<div v-if="!showMenu" class="absolute top-4 right-4 text-2xl cursor-pointer" @click="showMenu = true">
			<font-awesome-icon
				:icon="['fas', 'bars']"
				class="lg:hidden transition hover:scale-110 active:scale-95 cursor-pointer"
			/>
		</div>
		<div v-else class="absolute top-4 right-4 text-2xl cursor-pointer z-[999999]" @click="showMenu = false">
			<font-awesome-icon
				:icon="['fas', 'x']"
				class="lg:hidden transition hover:scale-110 active:scale-95 cursor-pointer"
			/>
		</div>

		<div ref="menu" class="absolute left-0 top-0 w-full pt-10 z-[9999] bg-white shadow-lg hidden">
			<Footer :show-animation="false" />
		</div>

		<Logo class="scale-75 md:scale-90 lg:scale-100" />
	</div>
</template>

<script>
import { gsap } from 'gsap';
import Logo from '@/assets/icons/Logo.vue';
import DiceIcon from '@/assets/icons/Dice.vue';
import ClickArrowIcon from '@/assets/icons/ClickArrowIcon.vue';
import { useGlobalStore } from '@/stores/global';
import Footer from '@/components/Footer.vue';

export default {
	name: 'HeaderComponent',
	components: {
		Logo,
		DiceIcon,
		ClickArrowIcon,
		Footer,
	},
	setup() {
		return {
			globalStore: useGlobalStore(),
		};
	},
	mounted() {
		gsap.from(this.$refs.header, {
			duration: 2,
			y: -this.$refs.header.clientHeight,
			ease: 'power4.out',
		});

		// hide menu on lg
		window.addEventListener('resize', () => {
			if (window.innerWidth >= 1024) {
				this.showMenu = false;
			}
		});
	},
	methods: {
		handleShowMenu() {
			gsap.fromTo(
				this.$refs.menu,
				{
					opacity: 0,
					y: -this.$refs.header.clientHeight,
				},
				{
					opacity: 1,
					y: 0,
					duration: 1,
					ease: 'power4.out',
					onStart: () => {
						this.$refs.menu.classList.remove('hidden');
					},
				},
			);
		},
		handleCloseMenu() {
			gsap.fromTo(
				this.$refs.menu,
				{
					opacity: 1,
					y: 0,
				},
				{
					opacity: 0,
					y: -this.$refs.menu.clientHeight,
					duration: 1,
					ease: 'power4.out',
					onComplete: () => {
						this.$refs.menu.classList.add('hidden');
					},
				},
			);
		},
		handleDiceClick() {
			this.animateDice();
		},
		bendDice() {
			gsap.to(this.$refs.dice.$el, {
				keyframes: [
					{ rotate: -10, x: -1, duration: 0.1, },
				],
				ease: 'power2.out',
			});
		},
		unBendDice() {
			gsap.to(this.$refs.dice.$el, {
				keyframes: [
					{ rotate: 0, x: 0, duration: 0.1, },
				],
				ease: 'power2.out',
			});
		},
		animateDice() {
			gsap.to(this.$refs.dice.$el, {
				keyframes: [
					{ rotate: -10, x: -1, duration: 0.1, },
					{ rotate: 10, x: 1, duration: 0.1, },
					{ rotate: -10, x: -1, duration: 0.1, },
					{ rotate: 10, x: 1, duration: 0.1, },
					{ rotate: -10, x: -1, duration: 0.1, },
					{ rotate: 10, x: 1, duration: 0.1, },
					{ rotate: 0, x: 0, duration: 0.1, },
				],
				ease: 'power2.out',
				onComplete: () => {
					this.globalStore.changeColors();
				},
			});
		},
	},
	computed: {
		showMenu: {
			get() {
				return this.globalStore.showMenu;
			},
			set(value) {
				this.globalStore.showMenu = value;
			},
		},
		showClickArrowIcon() {
			return this.$route.path === '/';
		},
	},
	watch: {
		showMenu() {
			if (this.showMenu) {
				this.handleShowMenu();
			} else {
				this.handleCloseMenu();
			}
		},
	},
};
</script>
