<template>
	<div class="w-full h-fit p-8 grid place-content-center relative">
		<div class="w-fit h-fit items-center justify-center gap-8 flex flex-wrap">
			<RouterLink
				v-for="link in links"
				:key="link.to"
				:to="link.to"
			>
				<span
					class="uppercase text-xl underline-offset-4 transition hover:underline"
					:class="{
						'underline': isActive(link.to),
					}"
					:style="{
						textDecorationColor: `rgba(0, 0, 0, ${isActive(link.to) ? 1 : 0.2})`,
					}"
				>
					{{ link.text }}
				</span>
			</RouterLink>
		</div>
	</div>
</template>

<script>
import { gsap } from 'gsap';
import { RouterLink } from 'vue-router';

export default {
	name: 'FooterComponent',
	components: {
		RouterLink,
	},
	props: {
		showAnimation: {
			type: Boolean,
			default: true,
		},
	},
	setup() {
		return {
			links: [
				{
					to: '/',
					text: 'Naslovna',
				},
				{
					to: '/ponuda',
					text: 'Ponuda',
				},
				// {
				// 	to: '/klijenti',
				// 	text: 'Klijenti',
				// },
				// {
				// 	to: '/cjenik',
				// 	text: 'Cjenik',
				// },
				{
					to: '/kontakt',
					text: 'Kontakt',
				},
			],
		};
	},
	mounted() {
		if (this.showAnimation) {
			gsap.from(this.$el, {
				duration: 2,
				y: this.$el.clientHeight,
				ease: 'power4.out',
			});
		}
	},
	methods: {
		isActive(to) {
			if (to === '/') {
				return this.$route.path === to;
			}

			return this.$route.path.startsWith(to);
		},
	},
};
</script>
