<template>
	<div class="w-full grid place-content-center relative">
		<div ref="titleText" class="w-fit h-fit relative">
			<div class="absolute top-0 left-0 -translate-y-full">
				<span
					class="text-clamp-sm uppercase underline underline-offset-4 font-display"
					:style="{
						textDecorationThickness: '0.15rem',
						textDecorationColor: 'var(--secondary-color)',
					}"
				>
					Tko smo mi?
				</span>
			</div>
			<h1 class="uppercase text-clamp-lg font-display">
				Mi smo tvoje online rješenje.
			</h1>
		</div>
	</div>
</template>

<script>
import { gsap } from 'gsap';

export default {
	name: 'LandingPage',
	mounted() {
		gsap.from(this.$refs.titleText, {
			duration: 2,
			scale: 0.5,
			opacity: 0,
			ease: 'power4.out',
		});
	},
}
</script>
