<template>
	<div ref="pricing" class="w-full max-w-[1500px] mx-auto grid grid-rows-3 lg:grid-rows-1 lg:grid-cols-3 gap-12 relative px-10 lg:px-24 py-4 lg:pt-20">
		<div ref="table1" class="w-full h-full flex justify-center items-start">
			<div class="w-fit h-fit relative max-w-[441px] pb-9">
				<SimpleTable heading="Izrada po narudžbi">
					<div class="w-full h-full grid place-content-start gap-4">
						<p>
							Izrada web stranice na zahtjev klienta, uključeno u cijenu:
						</p>

						<ul class="list-disc list-inside pl-4">
							<li>
								Registracija domene
							</li>
							<li>
								Hosting
							</li>
							<li>
								SSL certifikat
							</li>
							<li>
								SEO optimizacija
							</li>
							<li>
								Responsive dizajn
							</li>
							<li>
								24/7 podrška
							</li>
						</ul>

						<span class="uppercase text-primary text-xl">
							Cijena: 500€
						</span>
					</div>
				</SimpleTable>

				<div class="absolute -bottom-2 right-0">
					<Btn @click="goToRequest('Po narudžbi')">
						<span class="px-4 py-2">
							Pošalji upit
						</span>
					</Btn>
				</div>
			</div>
		</div>
		<div ref="table2" class="w-full h-full flex justify-center items-start">
			<div class="w-fit h-fit relative max-w-[441px] pb-9">
				<SimpleTable heading="Izrada po modelu">
					<div class="w-full h-full grid place-content-start gap-4">
						<p>
							Izrada web stranice po jednom od naših modela, uključeno u cijenu:
						</p>

						<ul class="list-disc list-inside pl-4">
							<li>
								Registracija domene
							</li>
							<li>
								Hosting
							</li>
							<li>
								SSL certifikat
							</li>
							<li>
								SEO optimizacija
							</li>
							<li>
								Responsive dizajn
							</li>
							<li>
								24/7 podrška
							</li>
						</ul>

						<span class="uppercase text-primary text-xl">
							Cijena: 300€
						</span>
					</div>
				</SimpleTable>

				<div class="absolute -bottom-2 right-0">
					<Btn @click="goToRequest('Po modelu')">
						<span class="px-4 py-2">
							Pošalji upit
						</span>
					</Btn>
				</div>
			</div>
		</div>
		<div ref="table3" class="w-full h-full flex justify-center items-start">
			<div class="w-fit h-fit relative max-w-[441px]">
				<SimpleTable heading="Dodatni elementi" :body-data="additionalElements" />
				<div class="absolute bottom-0 left-2 translate-y-full">
					<span class="text-xs">
						Za detaljni opis klikni
						<a
							href="#"
							class="text-primary link"
							@click="openModal"
						>
							ovdje
						</a>
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { gsap } from 'gsap';
import { markRaw } from 'vue';
import SimpleTable from '@/components/SimpleTable.vue';
import Btn from '@/components/Btn.vue';
import PricingDetailsModal from '@/components/modals/PricingDetailsModal.vue';
import { useModalStore } from '@/stores/modal';
import { useGlobalStore } from '@/stores/global';

export default {
	name: 'PricingPage',
	components: {
		SimpleTable,
		Btn,
	},
	data() {
		return {
			stlyeElement: 'text-left pl-1 pr-4 py-2',
			stylePrice: 'text-primary text-right pr-1 py-2',
		};
	},
	setup() {
		return {
			modalStore: useModalStore(),
			globalStore: useGlobalStore(),
			PricingDetailsModal: markRaw(PricingDetailsModal),
			additionalElements: [
				{
					element: 'Dodatna stranica',
					price: '50€',
				},
				{
					element: 'Dodatni strani jezik',
					price: '50€',
				},
				{
					element: 'Hosting',
					price: '50€',
				},
				{
					element: 'Izrada logotipa',
					price: '50€',
				},
				{
					element: 'SEO optimizacija',
					price: '50€',
				},
				{
					element: 'Održavanje i ažuriranje',
					price: '50€',
				},
			],
		};
	},
	mounted() {
		// animate tables droping down one after the other on screens larger than lg
		if (window.innerWidth > 1024) {
			gsap.from(this.$refs.table1, {
				duration: 1.5,
				y: -this.$refs.table1.clientHeight,
				opacity: 0,
				ease: 'power4.out',
			});
			gsap.from(this.$refs.table2, {
				duration: 1.5,
				y: -this.$refs.table2.clientHeight,
				opacity: 0,
				ease: 'power4.out',
				delay: 0.1,
			});
			gsap.from(this.$refs.table3, {
				duration: 1.5,
				y: -this.$refs.table3.clientHeight,
				opacity: 0,
				ease: 'power4.out',
				delay: 0.2,
			});
		} else {
			gsap.from(this.$refs.table1, {
				duration: 1.5,
				x: -this.$refs.table1.clientWidth,
				opacity: 0,
				ease: 'power4.out',
			});
			gsap.from(this.$refs.table2, {
				duration: 1.5,
				x: -this.$refs.table2.clientWidth,
				opacity: 0,
				ease: 'power4.out',
				delay: 0.1,
			});
			gsap.from(this.$refs.table3, {
				duration: 1.5,
				x: -this.$refs.table3.clientWidth,
				opacity: 0,
				ease: 'power4.out',
				delay: 0.2,
			});
		}
		
	},
	methods: {
		openModal() {
			this.modalStore.openModal(this.PricingDetailsModal, document.documentElement.scrollTop);
		},
		goToRequest(type) {
			this.globalStore.orderType = type;
			this.$router.push('/kontakt');
		}
	},
}
</script>
