import { createWebHistory, createRouter } from 'vue-router';
import LandingPage from '@/views/LandingPage.vue';
import ClientsPage from '@/views/ClientsPage.vue';
import PricingPage from '@/views/PricingPage.vue';
import ContactPage from '@/views/ContactPage.vue';
import { useGlobalStore } from './stores/global';

const routes = [
	{
		path: '/',
		name: 'Naslovna',
		component: LandingPage,
	},
	{
		path: '/cjenik',
		name: 'Cijenik',
		component: PricingPage,
	},
	{
		path: '/klijenti',
		name: 'Klijenti',
		component: ClientsPage,
	},
	{
		path: '/ponuda',
		name: 'Ponuda',
		component: ClientsPage,
	},
	{
		path: '/kontakt',
		name: 'Kontakt',
		component: ContactPage,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
	useGlobalStore().showMenu = false;

	if (to.matched.length === 0) {
		next('/');

		return;
	}

	next();
});

export default router;
