<template>
	<svg :width="size" :height="size" stroke-miterlimit="10" style="fill-rule:nonzero;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;" version="1.1" viewBox="0 0 1024 1024" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
		<g>
			<path d="M443.845 627.284L205.235 691.069C175.91 698.908 145.783 681.49 137.943 652.165L27.3386 238.408C19.4995 209.083 36.9173 178.956 66.2423 171.116L480 60.5117C509.325 52.6725 539.452 70.0903 547.291 99.4153L626.281 394.906" fill="none" opacity="1" :stroke="color" stroke-linecap="round" stroke-linejoin="round" :stroke-width="size"/>
			<path d="M969.687 834.187L963 914.938C960.494 945.189 933.94 967.681 903.689 965.176L476.865 929.827C446.613 927.321 424.121 900.767 426.626 870.516L461.976 443.692C464.481 413.44 491.035 390.948 521.287 393.453L948.111 428.803C978.362 431.308 1000.85 457.862 998.349 488.113L969.687 834.187" fill="none" opacity="1" :stroke="color" stroke-linecap="round" stroke-linejoin="round" :stroke-width="size"/>
			<path d="M294.794 334.878C309.111 308.95 341.736 299.537 367.664 313.853C393.592 328.17 403.005 360.795 388.689 386.723C374.372 412.651 341.747 422.064 315.819 407.747C289.891 393.431 280.478 360.806 294.794 334.878Z" :fill="color" fill-rule="nonzero" opacity="1" stroke="none"/>
			<path d="M658.859 679.315C658.859 649.696 682.869 625.686 712.488 625.686C742.106 625.686 766.116 649.696 766.116 679.315C766.116 708.933 742.106 732.943 712.488 732.943C682.869 732.943 658.859 708.933 658.859 679.315Z" :fill="color" fill-rule="nonzero" opacity="1" stroke="none"/>
			<path d="M385.22 180.013C399.536 154.085 432.161 144.672 458.089 158.988C484.017 173.305 493.43 205.93 479.114 231.858C464.797 257.786 432.172 267.199 406.244 252.882C380.316 238.566 370.903 205.941 385.22 180.013Z" :fill="color" fill-rule="nonzero" opacity="1" stroke="none"/>
			<path d="M206.378 495.005C220.694 469.077 253.319 459.664 279.247 473.981C305.175 488.297 314.588 520.922 300.272 546.85C285.955 572.779 253.33 582.192 227.402 567.875C201.474 553.558 192.061 520.934 206.378 495.005Z" :fill="color" fill-rule="nonzero" opacity="1" stroke="none"/>
		</g>
	</svg>
</template>

<script>
export default {
	name: 'DiceIcon',
	props: {
		size: {
			type: Number,
			default: 24,
		},
		color: {
			type: String,
			default: 'black',
		},
	},
};
</script>