<template>
	<div class="w-screen h-lvh">
		<div class="w-full min-h-lvh flex flex-col">
			<Header />
			<div class="w-full h-full grow flex">
				<RouterView />
			</div>
			<Footer class="mt-auto hidden lg:flex" />
		</div>

		<Modal />
	</div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import { RouterView } from 'vue-router';
import Modal from '@/components/Modal.vue';

export default {
	name: 'App',
	components: {
		RouterView,
		Header,
		Footer,
		Modal,
	},
};
</script>
